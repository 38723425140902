import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface Props {
  description?: string;
  lang?: string;
  meta?: [];
  title: string;
}

const SEO = ({ description, lang, meta, title }: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            url
            previewImage
            language
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  const metaImage = `${site.siteMetadata.url}${site.siteMetadata.previewImage}`;

  return (
    <Helmet
      htmlAttributes={{
        lang: lang || site.siteMetadata.language,
      }}
      title={`${title} | Rony Martins`}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          property: `robots`,
          content: `index, follow`,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
      ].concat(meta || [])}
    />
  );
};

export default SEO;
